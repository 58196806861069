import { createRouter, createWebHistory } from 'vue-router'

import { authGuard } from './auth.guard'
import { pageTitleMiddleware } from './pageTitle.middleware'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(authGuard)
router.beforeEach(pageTitleMiddleware)

export default router
